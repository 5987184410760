<template>
  <a-form ref="searchFormRef" layout="horizontal" :model="searchForm"
          class="m3-width-full m3-relative">
    <a-row gutter="20">
      <a-col span="8">
        <a-form-item label="质检状态">
          <a-checkbox v-model:checked="translated.checkAll"
                      :indeterminate="translated.indeterminate"
                      @change="onTranslatedCheckAllChange">全部
          </a-checkbox>
          <a-checkbox-group v-model:value="searchForm.translatedResultList"
                            :options="translatedList"
                            @change="onTranslatedChange"></a-checkbox-group>
        </a-form-item>
      </a-col>
      <a-col span="5">
        <a-form-item label="是否命中">
          <a-radio-group v-model:value="searchForm.hitTypeStatus" :options="hitTypeStatusList"/>
        </a-form-item>
      </a-col>
      <a-col span="6">
        <a-form-item label="主叫">
          <div class="m3-flex">
            <a-input v-model:value="searchForm.telAPrefix" placeholder="主叫前缀" class="m3-flex-1"/>
            <span class="m3-margin-horizontal-s">-</span>
            <a-input v-model:value="searchForm.telA" placeholder="主叫号码" class="m3-flex-3"/>
          </div>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row gutter="20">
      <a-col span="8">
        <a-form-item label="审核状态">
          <a-checkbox v-model:checked="auditStatus.checkAll"
                      :indeterminate="auditStatus.indeterminate"
                      @change="onAuditStatusCheckAllChange">全部
          </a-checkbox>
          <a-checkbox-group v-model:value="searchForm.auditStatusList"
                            :options="auditStatusList"
                            @change="onAuditStatusChange"></a-checkbox-group>
        </a-form-item>
      </a-col>
      <a-col span="5">
        <a-form-item label="是否已读">
          <a-radio-group v-model:value="searchForm.read" :options="readStatusList"/>
        </a-form-item>
      </a-col>
      <a-col span="6">
        <a-form-item label="被叫">
          <div class="m3-flex">
            <a-input v-model:value="searchForm.telBPrefix" placeholder="被叫前缀" class="m3-flex-1"/>
            <span class="m3-margin-horizontal-s">-</span>
            <a-input v-model:value="searchForm.telB" placeholder="被叫号码" class="m3-flex-3"/>
          </div>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row v-if="showMoreSearch" gutter="20">
      <a-col span="8">
        <a-form-item label="行业类型">
          <a-select v-model:value="searchForm.industry" :options="industryList" :style="{width:'60%'}"></a-select>
        </a-form-item>
      </a-col>
      <a-col span="5">
        <a-form-item label="风险等级">
          <a-select v-model:value="searchForm.risk" :options="riskList" :style="{width:'80%'}"></a-select>
        </a-form-item>
      </a-col>
      <a-col span="6">
        <a-form-item label="命中类型">
          <div class="m3-flex">
            <a-select v-model:value="searchForm.value" :options="valueList" class="m3-flex-2"></a-select>
            <span class="m3-margin-horizontal-s">-</span>
            <a-select v-model:value="searchForm[searchForm.value]"
                      :options="voiceNameList"
                      class="m3-flex-5"></a-select>
          </div>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row v-if="showMoreSearch" gutter="20">
      <a-col span="8">
        <a-form-item label="时间筛选">
          <el-date-picker v-model="searchDateTime"
                          :style="{width:'80%'}"
                          type="datetimerange" value-format="YYYY-MM-DD HH:mm:ss"
                          :default-time="[new Date(2000, 1, 1, 8, 0, 0),new Date(2000, 2, 1, 24, 0, 0),]"
                          :shortcuts="dateShortcuts"
                          range-separator="-" start-placeholder="开始" end-placeholder="结束"/>
          <!--            <a-range-picker-->
          <!--                :style="{width:'60%'}"-->
          <!--                :locale="locale"-->
          <!--                v-model:value="searchForm.timeArr"-->
          <!--                :show-time="{ format: 'HH:mm' }"-->
          <!--                format="YYYY-MM-DD HH:mm"-->
          <!--                :placeholder="['开始时间', '结束时间']"/>-->
        </a-form-item>
      </a-col>
      <a-col span="5">
        <a-form-item label="敏感词">
          <a-input v-model:value="searchForm.translatedContent" placeholder="敏感词" :style="{width:'80%'}"/>
        </a-form-item>
      </a-col>
      <a-col span="5">
        <a-form-item label="通话时间">
          <a-select v-model:value="callingDuration" :options="callingDurationList" :style="{width:'60%'}"></a-select>
        </a-form-item>
      </a-col>
    </a-row>

    <div class="m3-flex" :style="{position: 'absolute',bottom: '24px',right: '20px'}">
      <a-button :type="'primary'" @click="toSearch">
        查询
      </a-button>
      <a-button @click="resetSearch" class="m3-margin-left-s">
        重置
      </a-button>
      <a-button class="m3-margin-left-s" type="link" @click="showMoreSearch=!showMoreSearch">
        {{ showMoreSearch ? '收起' : '展开' }}
        <UpOutlined v-if="showMoreSearch"/>
        <DownOutlined v-else/>
      </a-button>
    </div>

  </a-form>
</template>

<script>
import AButton from "ant-design-vue/lib/button/button";
import {ASelect} from "ant-design-vue/lib/select";
import AInput from "ant-design-vue/lib/input/Input";
import {DownOutlined, UpOutlined} from "@ant-design/icons-vue";
import ACol from "ant-design-vue/lib/grid/Col";
import {ARow} from "ant-design-vue/lib/grid/Row";
import ACheckbox from "ant-design-vue/lib/checkbox/Checkbox";
import ACheckboxGroup from "ant-design-vue/lib/checkbox/Group";
import AFormItem from "ant-design-vue/lib/form/FormItem";
import {getDate} from "@/utils/timeToChoose";
import {computed, onMounted, reactive, ref, toRaw, watch} from "vue";
import record from "@/api/open/voiceCheck/record";
import voiceType from "@/api/open/voice/voiceType";
import {Form} from "ant-design-vue";
import ARadioGroup from "ant-design-vue/lib/radio/Group";
import EmptyUtil from "@/utils/common/EmptyUtil";
import {safePromise} from "@/utils/common/safePromise";
import _lineService from "@/api/open/LineQualityInspections";
import {useStore} from "vuex";

export default {
  name: "SearchForm",
  emits: ['change', 'search'],
  components: {
    ARadioGroup,
    AButton,
    ASelect,
    AInput,
    DownOutlined,
    UpOutlined,
    ACol,
    ARow,
    ACheckbox,
    ACheckboxGroup,
    AFormItem
  },
  setup(props, context) {

    let store = useStore();
    let startDateTime = getDate(0) + ' 00:00:00';
    let endDateTime = getDate(0) + ' 23:59:59';

    let searchForm = reactive({
      translatedResultList: [0, -1, 1],
      auditStatusList: [0, 1, 2, 3, 4],
      hitTypeStatus: '',
      read: '',
      telAPrefix: '',
      telA: '',
      telBPrefix: '',
      telB: '',
      translatedContent: '',//敏感词
      callerTranslatedContent: '',//主叫敏感词
      calleeTranslatedContent: '',//被叫敏感词
      industry: '',
      risk: null,
      value: '',//命中类型-条件
      createTimeBegin: startDateTime,
      createTimeEnd: endDateTime,
    });

    //初始化后将参数发给列表页以初始化列表
    context.emit('change', toRaw(searchForm));

    let showMoreSearch = ref(false);
    let onMouseOver = (e) => {
      // console.log('onMouseOver', e)
      showMoreSearch.value = true;
    }
    let onMouseOut = (e) => {
      // console.log('onMouseOut', e)
      showMoreSearch.value = false;
    }

    let searchDateTime = ref([startDateTime, endDateTime]);
    const dateShortcuts = [
      {
        text: '过去一周',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        },
      },
      {
        text: '过去一个月',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        },
      },
      {
        text: '过去三个月',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        },
      },
    ];
    watch(searchDateTime, (newV, preV) => {
      if (newV && newV.length > 1) {
        searchForm.createTimeBegin = newV[0];
        searchForm.createTimeEnd = newV[newV.length - 1];
      } else {
        searchForm.createTimeBegin = '';
        searchForm.createTimeEnd = '';
      }
    })

    let callingDuration = ref(0);
    let callingDurationValueList = [
      [],
      [0, 10],
      [10, 60],
      [60, 90],
      [90, 120],
      [120, ''],
    ]
    let callingDurationList = [
      {
        value: 0,
        label: '所有时间',
      },
      {
        value: 1,
        label: '0~10秒',
      },
      {
        value: 2,
        label: '10~60秒',
      },
      {
        value: 3,
        label: '60~90秒',
      },
      {
        value: 4,
        label: '90~120秒',
      },
      {
        value: 5,
        label: '大于120秒',
      },
    ]
    watch(callingDuration, (newV, preV) => {
      let values = callingDurationValueList[newV];
      console.log('watch callingDuration', newV, preV, values);
      if (EmptyUtil.isEmpty(values)) {
        delete searchForm.durationBegin;
        delete searchForm.durationEnd;
      } else {
        searchForm.durationBegin = values[0];
        searchForm.durationEnd = values[values.length - 1];
      }
    })
    //质检状态相关
    let translated = reactive({
      checkAll: true,
      indeterminate: false,
    })
    let translatedList = [
      {
        value: 0,
        label: '质检成功',
      },
      {
        value: -1,
        label: '质检中',
      },
      {
        value: 1,
        label: '质检失败',
      },
    ];

    let onTranslatedCheckAllChange = (e) => {
      let checked = e?.target?.checked;
      searchForm.translatedResultList = checked ? translatedList.map(item => item.value) : [];
      translated.indeterminate = false;
      // console.log('onCheckAllTranslatedResultListChange', checked, searchForm.translatedResultList);
    }
    let onTranslatedChange = (values) => {
      // console.log('translatedResultListChange', values);
      translated.checkAll = values && values.length == translatedList.length;
      translated.indeterminate = !!values?.length && values.length < translatedList.length;
    }

    //审核状态相关
    let auditStatus = reactive({
      checkAll: true,
      indeterminate: false,
    })
    let auditStatusList = [
      {
        value: 0,
        label: '未审核',
      },
      {
        value: 1,
        label: '正常',
      },
      {
        value: 2,
        label: '违规',
      },
      {
        value: 3,
        label: '误判',
      },
      {
        value: 4,
        label: '复审',
      },
    ];
    let onAuditStatusCheckAllChange = (e) => {
      let checked = e?.target?.checked;
      searchForm.auditStatusList = checked ? auditStatusList.map(item => item.value) : [];
      auditStatus.indeterminate = false;
      // console.log('onCheckAllTranslatedResultListChange', checked, searchForm.translatedResultList);
    }
    let onAuditStatusChange = (values) => {
      // console.log('translatedResultListChange', values);
      auditStatus.checkAll = values && values.length == auditStatusList.length;
      auditStatus.indeterminate = !!values?.length && values.length < auditStatusList.length;
    }

    let hitTypeStatusList = [
      {
        value: '',
        label: '全部',
      },
      {
        value: 1,
        label: '命中',
      },
      {
        value: 0,
        label: '未命中',
      },
    ];
    let readStatusList = [
      {
        value: '',
        label: '全部',
      },
      {
        value: 0,
        label: '未读',
      },
      {
        value: 1,
        label: '已读',
      },
    ];

    let industryList = ref([]);
    let getIndustryList = async () => {
      record.vosUserIndustry().then((res) => {
        industryList.value = res.data.map(item => {
          return {
            value: item,
            label: item,
          }
        })
        //添加一个全部
        industryList.value.splice(0, 0, {
          value: '',
          label: '全部',
        })
      })
    }

    let voiceNameList = ref([]);
    let getVoiceNameList = async () => {
      let uid = store.state.user.uid;
      let res = await safePromise(_lineService._vosService.getNameList({uid: uid}));
      // voiceType.voiceName().then((res) => {
      //   voiceNameList.value = res.data.map(item => {
      //     return {
      //       value: item.name,
      //       label: item.name,
      //     }
      //   })
      // })
      voiceNameList.value = (res?.code == 200) ? res?.data?.map(item => {
        return {
          value: item.name,
          label: item.name,
        }
      }) || [] : [];
    }

    let riskList = [
      {
        value: null,
        label: '全部',
      },
      {
        value: 3,
        label: '高',
        labelClass: 'risk-high-more'
      },
      {
        value: 2,
        label: '中',
        labelClass: 'risk-high'
      },
      {
        value: 1,
        label: '低',
        labelClass: 'risk-mid'
      },
      {
        value: 0,
        label: '无',
        labelClass: 'risk-low'
      },
    ];

    //命中类型
    let valueList = [
      {
        value: 'hitType',
        label: '等于',
      },
      {
        value: 'notHitType',
        label: '不等于',
      },
    ];

    //useForm 表单验证
    let useForm = Form.useForm;
    const {resetFields, validate, validateInfos, mergeValidateInfo} = useForm(searchForm, []);
    let toSearch = async () => {
      context.emit('search', toRaw(searchForm));
    }
    let resetSearch = async () => {
      resetFields();
      showMoreSearch.value = false;
      await toSearch();
    }
    onMounted(() => {
      getIndustryList();
      getVoiceNameList();
    })
    return {
      searchForm,
      showMoreSearch, onMouseOver, onMouseOut,
      translated, translatedList, onTranslatedCheckAllChange, onTranslatedChange,
      auditStatus, auditStatusList, onAuditStatusCheckAllChange, onAuditStatusChange,
      hitTypeStatusList, readStatusList, industryList, voiceNameList, riskList, valueList,
      searchDateTime, dateShortcuts,
      callingDuration, callingDurationList,
      toSearch, resetSearch,
    }
  },
}
</script>

<style scoped>

</style>