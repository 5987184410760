<template>
  <a-modal :visible="dialogVisible"
           title="对话详情" width="70%"
           @cancel="dialogClose">
    <div class="m3-flex m3-align-items-start"
         :style="{height:'550px !important',overflow:'scroll'}">
      <div class="m3-relative m3-height-full m3-flex-col m3-line-clamp-2">
        <a-descriptions bordered layout="horizontal" :column="1"
                        :style="{height:'95%',overflow:'scroll'}"
                        :content-style="{maxWidth:'200px'}">
          <a-descriptions-item label="服务器IP">{{ record.ip }}</a-descriptions-item>
          <a-descriptions-item label="主叫">{{ (record.telAPrefix || 'xx') }}-{{ record.telA.replace(/\s/g, '') }}</a-descriptions-item>
          <a-descriptions-item label="被叫">{{ (record.telBPrefix || 'xx') }}-{{ record.telB.replace(/\s/g, '') }}</a-descriptions-item>
          <a-descriptions-item label="通话时间">{{ recordTimeDesc }}</a-descriptions-item>
          <a-descriptions-item label="报备行业">{{ record.industry }}</a-descriptions-item>
          <a-descriptions-item label="风险等级">
            <span class="m3-padding-horizontal-s m3-padding-vertical-xxs" :class="riskTextClass">{{ riskText }}</span>
          </a-descriptions-item>
          <a-descriptions-item label="命中类型">{{ record.hitType }}</a-descriptions-item>
          <a-descriptions-item label="命中关键字">
            <a-tag class="m3-margin-right-s m3-margin-bottom-xs" :color="record.auditStatus==2?'error':'default'"
                   v-for="item in record.hitTypeDescArr" :key="item">
              {{ item }}
            </a-tag>
          </a-descriptions-item>
        </a-descriptions>
        <div class="m3-absolute m3-flex m3-margin-vertical-xs m3-padding-horizontal-s"
             :style="{left:'0px',bottom:'0px'}">
          <audio class="m3-width-full" id="audio" :src="record.fileUrl" controls="controls"></audio>
        </div>
      </div>

      <div class="m3-flex-1 m3-height-full m3-flex-col md-surface m3-border-radius-s m3-padding-l m3-margin-left-s"
           :style="{overflow:'scroll'}">
        <div v-for="(translatedContent,translatedContentIndex) in translatedContentList"
             :key="'translatedContent'+translatedContentIndex"
             class="m3-flex-col m3-margin-bottom-xs">
          <div v-if="isTelA(translatedContent)"
               class="m3-flex m3-justify-content-start">
            <a-avatar :size="40" :src="require(`@/assets/images/telA.png`)"></a-avatar>
            <div class="triangle-telA"></div>
            <div class="md-surface m3-padding-s m3-border-radius-xs"
                 :style="{maxWidth: '60%',backgroundColor:'#ffffff'}"
                 @click="positionAudio(translatedContent)"
                 v-html="$filter.mateKeyword(translatedContent?.text,record.hitTypeDescArr)"></div>
          </div>
          <div v-else
               class="m3-flex m3-justify-content-end">
            <div class="md-primary-container m3-padding-s m3-border-radius-xs"
                 :style="{maxWidth: '60%',backgroundColor:'#9eea6a'}"
                 @click="positionAudio(translatedContent)"
                 v-html="$filter.mateKeyword(translatedContent?.text,record.hitTypeDescArr)"></div>
            <div class="triangle-telB"></div>
            <a-avatar :size="40" :src="require(`@/assets/images/telB.png`)"></a-avatar>
          </div>

        </div>
      </div>
    </div>
    <template #footer>
      <div class="m3-flex ">
        <div class="m3-flex-1 m3-flex">
          <span class="m3-padding-horizontal-s m3-padding-vertical-xs"
                :class="auditStatusClass">{{ auditStatusDesc }}</span>
          <label class="m3-margin-horizontal-s">审核结果</label>
          <a-radio-group
              name="radioGroup"
              v-model:value="auditStatus"
              :options="auditStatusList">
          </a-radio-group>
        </div>
        <div class="m3-flex m3-justify-content-end">
          <a-button @click="toPre" :loading="nextLoading" :disabled="nextLoading" class="m3-margin-right-m">上一条
          </a-button>
          <a-button @click="toNext" :loading="nextLoading" :disabled="nextLoading" class="m3-margin-right-m">下一条
          </a-button>
          <a-button @click="dialogClose" class="m3-margin-right-m">取消</a-button>
          <a-button type="primary"
                    :loading="dialogLoading"
                    @click="dialogConfirm">确认
          </a-button>

        </div>

      </div>
    </template>
  </a-modal>
</template>

<script>
import AModal from "ant-design-vue/lib/modal/Modal";
import {toRefs, ref, watch, onUnmounted} from "vue";
import {ADescriptionsItem} from "ant-design-vue/lib/descriptions";
import voiceCheck from "@/api/open/voiceCheck/voiceCheck";
import EmptyUtil from "@/utils/common/EmptyUtil";
import {AAvatar} from "ant-design-vue/lib/avatar/Avatar";
import {safePromise} from "@/utils/common/safePromise";

export default {
  name: "RecordDialog",
  emits: ['update:dialogVisible', 'confirm'],
  components: {AAvatar, ADescriptionsItem, AModal},
  props: {
    dialogVisible: {
      type: Boolean,
      default: () => false,
    },
    record: {
      type: Object,
      default: {},
    }
  },
  setup(props, context) {
    let {record} = toRefs(props);
    let dialogLoading = ref(false);
    // 上一条、下一条loading
    let nextLoading = ref(false);

    let auditStatus = ref(1);//默认正常;
    let resetAuditStatus = () => {
      auditStatus.value = 1;
    }
    let auditStatusList = [
      {
        value: 1,
        label: '正常',
        className: 'risk-low',
      },
      {
        value: 2,
        label: '违规',
        className: 'risk-high-more',
      },
      {
        value: 3,
        label: '误判',
        className: 'risk-high',
      },
      {
        value: 4,
        label: '复审',
        className: 'risk-mid',
      },
    ]

    let riskList = [
      // {
      //   value: null,
      //   label: '全部',
      // },
      {
        value: 3,
        label: '高',
        labelClass: 'risk-high-more'
      },
      {
        value: 2,
        label: '中',
        labelClass: 'risk-high'
      },
      {
        value: 1,
        label: '低',
        labelClass: 'risk-mid'
      },
      {
        value: 0,
        label: '无',
        labelClass: 'risk-low'
      },
    ];

    let translatedContentList = ref([]);
    let getTranslatedContent = async (id) => {
      let res = await voiceCheck.getByCheckId({checkId: id}).catch(e => {
        console.log('getTranslatedContent e', e);
      });
      translatedContentList.value = [];
      if (res && res.data) {
        if (res.data.checkId == record.value?.checkId) {
          record.value.translatedContent = res.data?.translatedContent;
          let contentJson = JSON.parse(res.data.translatedContent);
          translatedContentList.value = contentJson?.payload?.sentences || [];
        }
      }
    }
    let changeReadStatus = (id) => {
      voiceCheck
          .vosCheck({
            id: id,
          })
          .then((res) => {
            if (record.value.id == id) {
              record.value.read = 1;
            }
          })
          .catch(e => {
            console.log('changeReadStatus e', e)
          })
    }
    watch(() => record.value?.id, async (newV, preV) => {
      // console.log('watch record.value?.id', newV, preV);
      if (newV && newV != preV) {
        resetAuditStatus();
        // 测试
        // let hitTypeDescArr = [];
        // for (let i = 0; i < 15; i++) {
        //   hitTypeDescArr.push('hitTypeDescArr' + i);
        // }
        // record.value.hitTypeDescArr = hitTypeDescArr;

        if (EmptyUtil.isEmpty(record.value?.translatedContent)) {
          await getTranslatedContent(newV);
        } else {
          let contentJson = JSON.parse(record.value.translatedContent);
          translatedContentList.value = contentJson?.payload?.sentences || [];
          // 测试
          // for (let i = 0; i < 20; i++) {
          //   translatedContentList.value.push({text: '测试'})
          // }
        }
        if (!record.value.read) {
          changeReadStatus(newV);
        }
        nextLoading.value = false;
      }
    }, {immediate: true});

    let audioTimeOut;
    //定位当前语音位置并播放
    let positionAudio = (sentence) => {
      // play()开始播放音频
      // pause()暂停当前播放的音频
      // load()重新加载音频元素
      // addTextTrack()在音频中添加一个新的文本轨道
      // fastSeek()在音频播放器中指定播放时间。
      // canPlayType()检查浏览器是否可以播放指定的音频类型
      // getStartDate()返回一个新的Date对象，表示当前时间轴偏移量
      let audio = document.getElementById("audio");
      if (null != audio && sentence.begin_time) {
        audio.currentTime = sentence.begin_time / 1000;//设置从哪里开始播放
        audio.play();
        let duration = sentence.end_time - sentence.begin_time;//结束时间减去开始时间就是时长，结合setTimeout实现只播放当前一段话
        audioTimeOut = setTimeout(() => {
          if (null != audio) {
            audio.pause();
          }
        }, duration);
      }
    }

    let nextLoadingTimeout;
    let setNextLoadingTimeout = () => {
      nextLoading.value = true;
      nextLoadingTimeout = setTimeout(() => {
        //设置5秒后结束loading，因为如果是第一页第一条或者最后一页最后一条这种极端条件，则不会向前加载更多
        nextLoading.value = false;
      }, 5000);
    }
    let toPre = async () => {
      context.emit('confirm', {index: -1});
      setNextLoadingTimeout();
    }
    let toNext = async () => {
      context.emit('confirm', {index: 1});
      setNextLoadingTimeout();
    }
    let dialogClose = () => {
      context.emit('update:dialogVisible', false);
    }
    let dialogConfirm = async () => {
      dialogLoading.value = true;
      let res = await safePromise(voiceCheck
          .vosAudit({
            id: record.value.id,
            auditStatus: auditStatus.value,
          }));
      dialogLoading.value = false;
      if (res?.code == 200) {
        record.value.auditStatus = auditStatus.value;
        context.emit('confirm', {index: 1});
        setNextLoadingTimeout();
        resetAuditStatus();
      }
    }

    onUnmounted(() => {
      if (audioTimeOut) {
        clearTimeout(audioTimeOut);
      }
      if (nextLoadingTimeout) {
        clearTimeout(nextLoadingTimeout);
      }
    })
    return {
      translatedContentList, positionAudio,
      auditStatus, auditStatusList, riskList,
      toPre, toNext, nextLoading,
      dialogClose, dialogConfirm, dialogLoading,
    }
  },
  computed: {
    isTelA() {
      return (translatedContent) => {
        return translatedContent?.channel_id == 1;
      }
    },
    auditStatusDesc() {
      if (this.record?.auditStatus == '1') {
        return '正常'
      } else if (this.record?.auditStatus == '2') {
        return '违规'
      } else if (this.record?.auditStatus == '3') {
        return '误判'
      } else if (this.record?.auditStatus == '4') {
        return '复审'
      } else if (this.record?.auditStatus == '0') {
        return '未审核'
      }
      return '未知'
    },
    auditStatusClass() {
      let res = '';
      let find = this.auditStatusList.find(item => item.value == this.record?.auditStatus);
      return find?.className || 'risk-low'
    },
    riskText() {
      let res = '';
      let find = this.riskList.find(risk => risk.value == this.record?.risk);
      res = find?.label || '';
      return res;
    },
    riskTextClass() {
      let res = '';
      let find = this.riskList.find(risk => risk.value == this.record?.risk);
      res = find?.labelClass || '';
      return res;
    },
    recordTimeDesc() {
      let res = '';
      let timestamp = this.record?.timestamp;
      if (timestamp) {
        res = timestamp.substring(0, 4) +
            '-' +
            timestamp.substring(4, 6) +
            '-' +
            timestamp.substring(6, 8) +
            ' ' +
            timestamp.substring(8, 10) +
            ':' +
            timestamp.substring(10, 12) +
            ':' +
            timestamp.substring(12, 14)
      }
      return res;
    }
  }
}
</script>

<style lang="scss" scoped>

.triangle-telA {
  position: relative;
  left: 2px;
  //top: 39%;
  //left: 44px;
  transform: rotate(-90deg);
  border-style: solid;
  border-width: 0px 6px 10px 6px;
  opacity: 1.52;
  border-color: transparent transparent #ffffff transparent;
  width: 0px;
  height: 0px;
}

.triangle-telB {
  position: relative;
  left: -2px;
  transform: rotate(90deg);
  border-style: solid;
  border-width: 0px 6px 10px 6px;
  opacity: 1.52;
  border-color: transparent transparent #9eea6a transparent;
  width: 0px;
  height: 0px;
}
</style>