// 质检记录
<template>
  <div class="out-box">
    <el-tabs v-model="activeName" >
      <el-tab-pane label="质检记录" name="first">
        <!--            <qualityRecord/>-->
        <vos-record/>
      </el-tab-pane>
      <el-tab-pane label="数据统计" name="second">
        <dataStatistics v-if="userType == 'system'"/>
        <userDataStatistics v-else/>
      </el-tab-pane>
      <el-tab-pane label="配置管理" name="third">
        <configPage/>
      </el-tab-pane>
      <el-tab-pane label="关键词配置" name="fourth">
        <UserControllerType/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import configPage from './vosQuality/configManagement'
import dataStatistics from './vosQuality/dataStatistics'
import userDataStatistics from './vosQuality/userDataStatistics'
// import qualityRecord from './vosQuality/qualityRecord'
import UserControllerType from './vosQuality/UserControllerType.vue'
import VosRecord from "@/views/open/vosQuality/record/VosRecord.vue";

export default {
  components: {
    VosRecord,
    configPage,
    dataStatistics,
    userDataStatistics,
    // qualityRecord,
    UserControllerType
  },
  data() {
    return {
      activeName: 'first',
      userType: this.$store.state.userType,
    }
  },
  created() {

  },
  watch: {},
  mounted() {
    if (this.$route.query.activeName != undefined) {
      this.activeName = this.$route.query.activeName
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab.props.name);
      this.$router.push({
        path: '/open/VosQuality',
        query: {
          activeName: tab.props.name,
          data: null
        }
      })
      // this.$router.push({
      //   // path: '/open/wechat/record/index',
      //   name:'wechatRecord',
      //   query: {
      //     activeName: tab.props.name,
      //     data: null
      //   }
      // })

    }
  },
}
</script>

<style lang="scss" scoped>
.out-box {
  margin-right: 0.9375rem;
}
</style>